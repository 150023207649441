import React from 'react';
import { FaDiscord, FaPlay, FaArchway } from 'react-icons/fa6';
import pepe2 from '../images/hermes4.png';
import shib from '../images/shib.png';
import icarus from '../images/icarus3.png';
import harambe1 from '../images/harambe1.2.png';
import harambe2 from '../images/harambe1.3.png';
import chronos from '../images/chronos.png';

const Tools = () => {
  return (
    <div className="tools-container" style={{
      padding: "40px 20px",
      maxWidth: "1200px",
      margin: "0 auto",
      display: "flex",
      flexWrap: "wrap",
      gap: "40px",
      justifyContent: "center"
    }}>
      <div className="tool-card">
        <h2>Heremes</h2>
        <img 
          src={pepe2}
          alt="Hermes"
          className="tool-image"
        />
        <h2>Mass Sniper</h2>
        <p>Buy tokens and split across many wallets. Multiply holders in an instant.</p>

        <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
          <a 
            href="https://youtu.be/PKWbbbhznq8?si=WUlOhPSjGnEAogEy" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaPlay style={{ marginRight: '8px' }}  />
            Watch Demo
          </a>
          <a 
            href="https://discord.gg/8W8TdfKXMT" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaDiscord style={{ marginRight: '8px' }} size={20}  />
            Get Access
          </a>
        </div>
      </div>
      <div className="tool-card">
        <h2>Pump Calculator</h2>
        <img 
          src={shib}
          alt="Pump Calculator"
          className="tool-image"
        />
        <h2>Trade Calculator</h2>
        <p>Stop blowing your port. Find out how much you actually pay in fees. Trade smart.</p>

        <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-around' }}>
          {/* <a 
            href="https://youtu.be/PKWbbbhznq8?si=WUlOhPSjGnEAogEy" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaPlay style={{ marginRight: '8px' }}  />
            Watch Demo
          </a> */}
          <a 
            href="https://pumpcalculator.fun"
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaArchway style={{ marginRight: '8px' }} size={20}  />
            Get Access
          </a>
        </div>
      </div>

      <div className="tool-card">
        <h2>OG Pump</h2>
        <img 
          src={harambe1}
          alt="Icarus"
          className="tool-image"
        />
        <h2>Find OG Coins</h2>
        <p>This tool helps you find OG coins. Analyse tokens quickly. Verify Authenticity</p>

        <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-around' }}>
          {/* <a 
            href="https://youtu.be/PKWbbbhznq8?si=WUlOhPSjGnEAogEy" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaPlay style={{ marginRight: '8px' }}  />
            Watch Demo
          </a> */}
          <a 
            href="https://ogpump.fun"
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaArchway style={{ marginRight: '8px' }} size={20}  />
            Get Access
          </a>
        </div>
      </div>


      <div className="tool-card">
        <h2>Chronos</h2>
        <img 
          src={chronos}
          alt="Icarus"
          className="tool-image"
        />
        <h2>Dex Paid Scanner</h2>
        <p>Quickly find out if and when a projet pays for dexscreener profile. </p>

        <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-around' }}>
          <a 
            href="https://www.youtube.com/watch?v=SBsyokV0aAQ" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaPlay style={{ marginRight: '8px' }}  />
            Watch Demo
          </a>
          <a 
            href="https://discord.gg/8W8TdfKXMT"
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaArchway style={{ marginRight: '8px' }} size={20}  />
            Get Access
          </a>
        </div>
      </div>

      
      <div className="tool-card">
        <h2>Icarus</h2>
        <img 
          src={icarus}
          alt="Icarus"
          className="tool-image"
        />
        <h2>Token Launcher</h2>
        <p>Launch tokens at lighting speed. With volume & comment bots. + Hidden Features</p>

        <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-around' }}>
          {/* <a 
            href="https://youtu.be/PKWbbbhznq8?si=WUlOhPSjGnEAogEy" 
            target="_blank" 
            rel="noreferrer"
            className="access-button"
          >
            <FaPlay style={{ marginRight: '8px' }}  />
            Watch Demo
          </a> */}
          <a 
            // href="https://pumpcalculator.fun"
            // target="_blank" 
            // rel="noreferrer"
            className="access-button"
          >
            <FaArchway style={{ marginRight: '8px' }} size={20}  />
            Coming Soon
          </a>
        </div>
      </div>

  

    </div>
  );
};

export default Tools; 