// src/pages/PrivacyPolicyUpload.js
import React from 'react';

const PrivacyPolicyUpload = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const fileInput = document.getElementById('fileInput');
    const messageDiv = document.getElementById('message');

    if (fileInput.files.length > 0) {
      // Simulate file upload process
      messageDiv.textContent = 'File uploaded successfully: ' + fileInput.files[0].name;
    } else {
      messageDiv.textContent = 'Please select a file to upload.';
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1> Privacy Policy</h1>
      
      <h2>1. Introduction</h2>
      <p>
        This Privacy Policy explains how the Dex Paid Status Checker Chrome extension ("we," "our," or "the extension") collects, uses, and protects your information when you use our extension. By using the extension, you agree to the collection and use of information in accordance with this policy.
      </p>
      <h2>2. Information We Collect</h2>
      <p>
        The Dex Paid Status Checker does not collect any personally identifiable information (PII) from users. We do not request, store, or process any personal data such as names, email addresses, or financial information.
      </p>
      <h2>3. Data Usage</h2>
      <p>
        The extension interacts with the Dex Screener API to provide real-time information about token payment statuses. The following data may be accessed during this process: Token Address: The extension retrieves the token address from the URL of the active tab to check its payment status.
      </p>
      <h2>4. Cookies and Tracking Technologies</h2>
      <p>
        The Dex Paid Status Checker does not use cookies or any tracking technologies to collect user data.
      </p>
      <h2>5. Data Security</h2>
      <p>
        We take the security of your information seriously. While we do not collect personal data, we implement reasonable security measures to protect any data processed through the extension.
      </p>
      <h2>6. Third-Party Services</h2>
      <p>
        The extension may use third-party services, such as the Dex Screener API, to provide functionality. We do not control these services and are not responsible for their privacy practices. We encourage you to review the privacy policies of any third-party services you use.
      </p>
      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </p>
      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us via: social@azukalabs.com.
      </p>
      
      <div id="message"></div>
    </div>
  );
};

export default PrivacyPolicyUpload;